var $class="se2--embed-code-global",$name="EmbedCodeGlobal",$path="app/components/EmbedCodeGlobal/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

const contactUsChatId = 'contact-us-chat';
const offlineClassName = 'offline';
let wasObserverConfigured = false;

export default shell.registerComponent($this, ({ configs, mount, publishMessage }) => {
  mount((element) => {
    const contactUsChatElement = element.querySelector(`#${contactUsChatId}`);

    if (!contactUsChatElement) {
      return;
    }

    // Publish message when embedded chat changes own state ('available' | 'unavailable').
    // Publishing of the message is needed to hide contact form if chat is available.
    const contactUsChatElementObserver = new MutationObserver(() => {
      const markerElement = contactUsChatElement.querySelector('section');

      if (!markerElement || wasObserverConfigured) {
        return;
      }

      const markerElementObserver = new MutationObserver(() => {
        const isChatAvailable = !markerElement.classList.contains(offlineClassName);

        publishMessage(
          configs.messages.embeddedChat,
          {
            state: isChatAvailable ? 'available' : 'unavailable',
          },
        );
      });

      markerElementObserver.observe(markerElement, { attributes: true });
      wasObserverConfigured = true;
    });

    contactUsChatElementObserver.observe(contactUsChatElement, { childList: true });
  });
});
